import { Card } from "react-bootstrap";

const SupplierSnippet = (props) => {
  const { defaulter, wallet_balance = null } = props;
  return (
    <Card className="shadow p-2 border-0">
      <Card.Body>
        <Card.Title>
          <strong>Supplier</strong>
        </Card.Title>

        <h6>{defaulter?.supplier_trade_name}</h6>
        <div>{defaulter?.supplier_gstn}</div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div>
            Active Balance:{" "}
            <span style={{ color: "green" }}>
              {wallet_balance?.totalActiveBalance}
            </span>{" "}
          </div>
          <div>
            Inactive Balance:{" "}
            <span style={{ color: "red" }}>
              {wallet_balance?.totalInActiveBalance}
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
export default SupplierSnippet;
