import * as apiService from "../services/api";
import * as API_URL from "../constants/api";
import { getToken } from "../helpers/common";
const getRMChangeRequestList = async (data = {}) => {
  try {
    let token = getToken();
    let result = await apiService.post(API_URL.RM_CHANGE_REQUEST_LIST,data, token);
    return result;
  } catch (e) {
    return { status: false, data: {}, message: e.message };
  }
};

const getOtherRMHandlingSamePlans = async(data)=>{
  try{
    const token = getToken()
    const result = await apiService.post(API_URL.OTHER_RM_HANDLING_SAME_PLANS,data,token)
    return result
  }catch(error){
    console.log("Error in getOtherRMHandlingSamePlans: ", error)
  }
}
const updateRM = async(data) => {
  const token = getToken()
  const result = await apiService.post(API_URL.UPDATE_RM,data,token)
    return result
}
const getRMCRTotalCount = async(data) => {
  const token = getToken()
  const result = await apiService.post(API_URL.RMCQ_TOTAL_COUNT,data,token)
  return result
}
const RmChangeRequestServices = {
  getRMChangeRequestList,
  getOtherRMHandlingSamePlans,
  updateRM,
  getRMCRTotalCount,
};

export default RmChangeRequestServices;
