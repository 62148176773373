import { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import * as yup from "yup";

import { useFormik, Field, ErrorMessage, Formik } from "formik";
import { Link } from "react-router-dom";
import { dateDifference } from "../../services/Misc";
import { toast } from "react-toastify";
import BuyerSnippet from "../Defaulter/BuyerSnippet";
import SupplierSnippet from "../Defaulter/SupplierSnippet";
import DefaulterNumberBox from "./DefaulterNumberBox";
import { getToken } from "../../helpers/common";
import * as apiService from "../../../src/services/api";
import * as API_URL from "../../constants/api";
import { addDays } from "../../helpers/common";

const CallRequestForm = (props) => {
  const {
    defaulter,
    onformSubmit,
    holdFor,
    isDisabled,
    contacts,
    permission,
    source,
  } = props;

  const [installmentAmount, setInstallmentAmount] = useState();
  const [totalDaysHold, setTotalDaysHold] = useState(0);
  const [holdFromDate, setHoldFromDate] = useState("");
  const [holdToDate, setHoldToDate] = useState("");
  const [paymentProofFileBase64, setPaymentProofFileBase64] = useState("");
  const [paymentProofFileName, setPaymentProofFileName] = useState("");
  const [isPartialEnabled, setIsPartialEnabled] = useState(false);
  const [defaulterContacts, setDefaulterContacts] = useState([]);
  const [defaulterNumbersList, setDefaulterNumbersList] = useState([]);
  const [minHoldFromDate, setMinHoldFromDate] = useState("");
  const [partialCallHoldPremission, setPartialCallHoldPermission] =
    useState(false);
  const backButtonLink =
    source === "creditqDefaulter"
      ? `/creditq-defaulters/${defaulter?.defaulter_id}`
      : `/defaulter/${defaulter?.id}`;
  const userAuth = localStorage.getItem("user");
  let userId = JSON.parse(userAuth).data.id;
  const daysDifference = async () => {
    const days = await dateDifference(holdFromDate, holdToDate);
    console.log("days from create", days);

    setTotalDaysHold(days + 1);
  };
  const getOngoingCallHold = async () => {
    try {
      let token = getToken();
      const payload = {
        defaulterId: defaulter?.defaulter_id,
        supplierId: defaulter?.supplier_id,
      };
      const result = await apiService.post(
        API_URL.CHECK_FOR_ONGOING_CALLHOLD,
        payload,
        token
      );
      if (result.data) {
        setMinHoldFromDate(addDays(result.data[0].hold_to, 1));
      } else {
        setMinHoldFromDate(new Date().toISOString().split("T")[0]);
      }
    } catch (error) {
      console.log("Error in getOngoingCallHold: ", error);
    }
  };
  useEffect(() => {
    getOngoingCallHold();
  }, []);
  const keysToExtract = [
    "aadhar_mobile",
    "aadhar_number",
    "buyer_mobile",
    "buyer_mobile_secondary",
    "defaulters_mobile",
    "defaulters_mobile_secondary",
  ];

  useEffect(() => {
    if (permission && permission.data && permission.data.length > 0) {
      permissionSet(permission.data);
    }
  }, [permission]);

  const permissionSet = async (data) => {
    data.forEach((valueSearch) => {
      if (
        valueSearch &&
        valueSearch.title &&
        valueSearch.title === "Partial Call hold create MSME" &&
        valueSearch.add_edit === 1
      ) {
        setPartialCallHoldPermission(true);
      }
    });
  };

  useEffect(() => {
    daysDifference();

    const contactValue = [
      ...(contacts
        ? contacts
            .filter((contact) => contact.contact_type === "mobile")
            .map((contact) => contact.contact_value)
        : []),
      ...keysToExtract.map((key) => defaulter[key]),
    ].filter((value) => value !== null && value !== "");

    setDefaulterContacts(contactValue);
  }, [holdFromDate && holdToDate]);

  const _selectFiles = async (e) => {
    var fileToLoad = e.target.files[0];
    //setUploadedFileName({ fileToLoad, docType })
    var fileReader = new FileReader();
    var base64 = [];
    fileReader.onload = function (fileLoadedEvent) {
      base64.push(fileLoadedEvent.target.result);
      setPaymentProofFileBase64(base64);
      setPaymentProofFileName(fileToLoad.name);
    };
    if (fileToLoad?.size <= 2000000) {
      fileReader.readAsDataURL(fileToLoad);
    } else {
      toast.error("File is too large.");
    }
  };

  const goPartialHandler = () => {
    // Toggle the state to enable/disable the partial section
    setIsPartialEnabled(!isPartialEnabled);
  };

  const handleDefaulterNumberChange = (e, index) => {
    let enteredNumber = e.target.value;
    // addMoreDefaulterNumber();

    // Check if 10 digits are entered
    if (enteredNumber.length === 10) {
      const isNumberInList = defaulterContacts.includes(enteredNumber);
      const alreadyTyped = defaulterNumbersList.includes(enteredNumber);

      if (isNumberInList && !alreadyTyped && enteredNumber !== "") {
        const updatedNumbersList = [...defaulterNumbersList];
        updatedNumbersList[index] = enteredNumber;
        setDefaulterNumbersList(updatedNumbersList);
        toast.success(`Defaulter number ${enteredNumber} added successfully!`);
        e.target.value = "";
      } else if (alreadyTyped) {
        toast.error(`You've already added this number.`);
      } else {
        toast.error(`Please enter a number related to the defaulter.`);
      }
    } else if (enteredNumber.length > 10) {
      // If more than 10 digits are entered, prevent further input
      toast.error(`Please enter only 10 digits.`);
      e.preventDefault(); // Prevent further input
    }
  };

  const installmentAmountChangeHandler = (event) => {
    try {
      formik.setFieldValue("installment_amount", event.target.value);
      setInstallmentAmount(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const SUPPORTED_FORMATS = ["jpg", "jpeg", "png", "pdf"];
  let paidToschema = yup.object().shape({
    installment_amount: yup.number().when("holdFor", {
      is: "AdvanceServiceCharge",
      then: yup
        .number()
        .positive()
        .min(1)
        .required("Enter a valid installment amount."),
      otherwise: yup.number(),
    }),

    hold_to: yup.date().required("Hold to date is required"),
    hold_from: yup.date().required("Hold from date is required"),
    remarks: yup.string().required("Remarks is required field."),
    // payment_proof: yup
    //   .mixed()
    //   .nullable()
    //   .when("holdFor", (holdFor, schema) => {
    //     if (holdFor === "AdvanceServiceCharge") {
    //       return schema
    //         .required("Payment proof is a required field")
    //         .test(
    //           "format",
    //           "Only the following formats are accepted: .jpeg, .jpg, .png and .pdf",
    //           (value) => {
    //             const paymentProofFileName = value && value.name;
    //             return (
    //               paymentProofFileName &&
    //               SUPPORTED_FORMATS.includes(
    //                 paymentProofFileName.split(".").pop().toLowerCase()
    //               )
    //             );
    //           }
    //         );
    //     }
    //     return schema; // Return the original schema if condition is not met
    //   }),
    discount_offered: yup.number().positive().min(0).max(100),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      supplier_id: defaulter.supplier_id,
      defaulterNumbers: "",
      installment_amount: "",
      hold_from: "",
      hold_to: "",
      remarks: "",
      payment_proof: "",
      payment_proof_name: "",
      payment_proof_file: "",
      defaulter_case_id: defaulter?.defaulter_id,
      holdFor: holdFor,
      status: "pending",
      remark_by: userId,
      remark_for: "other",
      payment_proof_details: {},
      source:
        source === "creditqDefaulter"
          ? "MSME_CREDITQ_DEFAULTER"
          : "MSME_DEFULTER",
    },
    validationSchema: paidToschema,
    onSubmit: (values) => {
      console.log("#########this is value ", values);
      values.hold_from = values.hold_from;
      values.hold_to = values.hold_to;
      values.holdFor = holdFor;
      values.remark_by = userId;
      values.defaulterNumbersList = defaulterNumbersList;
      values.source =
        source === "creditqDefaulter"
          ? "MSME_CREDITQ_DEFAULTER"
          : "MSME_DEFAULTER";
      const callHoldType =
        defaulterNumbersList.length === 0 || defaulterNumbersList === null
          ? "complete"
          : "partial";
      values.callHoldType = callHoldType;

      if (values.payment_proof !== "") {
        values.payment_proof_name = paymentProofFileName;
        values.installment_amount = values.installment_amount;
        values.payment_proof_file = paymentProofFileBase64;
        values.payment_proof_details.name = values.payment_proof.name;
        values.payment_proof_details.type = values.payment_proof.type;
        values.payment_proof_details.size = values.payment_proof.size;
      } else {
        values.payment_proof = "";
      }

      onformSubmit(values);
    },
  });

  return (
    <>
      <Row className="mb-5">
        <Col>
          <BuyerSnippet defaulter={defaulter} />
        </Col>
        <Col>
          <SupplierSnippet defaulter={defaulter} />
        </Col>
      </Row>

      <Row>
        <Col>
          <Row className="g-2">
            {defaulterNumbersList.map((number, index) => (
              <Col md={2} key={index} className="mb-3">
                <DefaulterNumberBox
                  number={number}
                  onDelete={(deletedNumber) => {
                    const updatedNumbersList = defaulterNumbersList.filter(
                      (num) => num !== deletedNumber
                    );
                    setDefaulterNumbersList(updatedNumbersList);
                  }}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Formik // Wrap your form with Formik
            initialValues={formik.initialValues}
            validationSchema={formik.validationSchema}
            onSubmit={formik.onSubmit}
          >
            <form
              onSubmit={formik.handleSubmit}
              method="post"
              encType="multipart/form-data"
            >
              {holdFor === "AdvanceServiceCharge" && (
                <Row className="mt-3">
                  <Col md={6} className="mx-auto">
                    <div className="form-floating">
                      <input
                        name="installment_amount"
                        type="number"
                        className="form-control"
                        placeholder="Enter installment amount"
                        size="sm"
                        // value={formik.values.installment_amount}
                        onChange={installmentAmountChangeHandler}
                      />
                      <label htmlFor="installment_amount">
                        Installment Amount
                      </label>
                    </div>
                    {formik.touched.installment_amount &&
                    formik.errors.installment_amount ? (
                      <span className="error text-danger">
                        {formik.errors.installment_amount}
                      </span>
                    ) : null}
                  </Col>
                </Row>
              )}
              {/* Row for Defaulter Numbers */}
              {isPartialEnabled && (
                <Row className="mt-3">
                  <Col md={6} className="mx-auto">
                    <>
                      <div className="form-floating">
                        <input
                          name={`defaulterNumbers`}
                          type="text"
                          pattern="[0-9]*"
                          className="form-control"
                          placeholder={`Enter defaulter number ${
                            defaulterNumbersList.length + 1
                          }`}
                          size="sm"
                          value={
                            defaulterNumbersList.values[
                              defaulterNumbersList.length
                            ]
                          }
                          onChange={(e) =>
                            handleDefaulterNumberChange(
                              e,
                              defaulterNumbersList.length
                            )
                          }
                        />
                        <label
                          htmlFor={`defaulterNumbers[${defaulterNumbersList.length}]`}
                        >
                          Defaulter Number {defaulterNumbersList.length}
                        </label>
                      </div>
                      <br />
                      {/* <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          handleDefaulterNumberChange(
                            { target: { value: inputRef.current.value } },
                            defaulterNumbersList.length
                          );
                        }}
                        disabled={
                          !defaulterNumbersList[defaulterNumbersList.length - 1]
                        }
                      >
                        Add
                      </Button> */}
                    </>
                  </Col>
                </Row>
              )}

              {/* Button to Toggle Partial Section */}
              {partialCallHoldPremission && (
                <Row className="mt-3">
                  <Col md={6} className="mx-auto">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={goPartialHandler}
                    >
                      {isPartialEnabled ? "Cancel Partial" : "Go Partial"}
                    </Button>
                  </Col>
                </Row>
              )}

              <Row className="mt-3">
                <Col md={6} className="mx-auto">
                  <div className="form-floating">
                    <Field
                      name="hold_from"
                      type="date"
                      className="form-control"
                      placeholder="Enter Call Hold From Date"
                      size="sm"
                      value={holdFromDate}
                      onChange={(e) => {
                        formik.setFieldValue("hold_from", e.target.value);
                        setHoldFromDate(e.target.value);
                      }}
                      min={minHoldFromDate}
                    />
                    {formik.touched.hold_from && formik.errors.hold_from ? (
                      <span className="error text-danger">
                        {formik.errors.hold_from}
                      </span>
                    ) : null}
                    <ErrorMessage
                      name="Hold_From"
                      component="span"
                      className="errorText"
                    />
                    <label htmlFor="hold_from">Hold From Date</label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} className="mx-auto">
                  <div className="form-floating">
                    <Field
                      name="hold_to"
                      type="date"
                      className="form-control"
                      placeholder="Enter Call Hold From Date"
                      size="sm"
                      value={holdToDate}
                      onChange={(e) => {
                        formik.setFieldValue("hold_to", e.target.value);
                        setHoldToDate(e.target.value);
                      }}
                      min={minHoldFromDate}
                    />{" "}
                    {formik.touched.hold_to && formik.errors.hold_to ? (
                      <span className="error text-danger">
                        {formik.errors.hold_to}
                      </span>
                    ) : null}
                    <ErrorMessage
                      name="hold_to"
                      component="span"
                      className="errorText"
                    />
                    <label htmlFor="hold_to">Hold To Date</label>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6} className="mx-auto">
                  <div className="form-floating">
                    <input
                      name="total_number_days"
                      id="total_number_days"
                      type="number"
                      className="form-control"
                      placeholder="Call hold for days"
                      size="sm"
                      value={totalDaysHold}
                      readOnly
                    />
                    <label htmlFor="total_payable_amount">No of days</label>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6} className="mx-auto">
                  <div className="form-floating">
                    <Form.Group controlId="formFile" className="mb-3">
                      <label htmlFor="payment_proof">Attachment</label>
                      <Form.Control
                        type="file"
                        name="payment_proof"
                        onChange={(event) => {
                          console.log(
                            "file data###############",
                            event.currentTarget.files[0]
                          );
                          formik.setFieldValue(
                            "payment_proof",
                            event.currentTarget.files[0]
                          );
                          _selectFiles(event);
                        }}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <div className="form-floating">
                    <textarea
                      name="remarks"
                      id="remarks"
                      className="form-control"
                      placeholder="Enter your remark"
                      size="sm"
                      style={{ minHeight: "150px" }}
                      onChange={formik.handleChange}
                    ></textarea>
                    <label htmlFor="total_payable_amount">Agent Remarks</label>
                  </div>
                  {formik.touched.remarks && formik.errors.remarks ? (
                    <span className="error text-danger">
                      {formik.errors.remarks}
                    </span>
                  ) : null}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="mt-3 text-center">
                  <Link to={backButtonLink}>
                    <button className="btn btn-secondary me-5">Back</button>
                  </Link>
                  <Button
                    variant="primary"
                    className="btn btn-success"
                    size="lg"
                    type="submit"
                    disabled={isDisabled}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default CallRequestForm;
