import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import RmChangeRequestServices from "../../services/rmChangeRequestService";
export const getRMChangeList = createAsyncThunk(
  "relationship-manager/change-request",
  async (data = {},thunkAPI) => {
    try {
      const result = await RmChangeRequestServices.getRMChangeRequestList(data);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const rmcrCount = createAsyncThunk(
  "relationship-manager/change-request/total-count",
  async (data = {},thunkAPI) => {
    try {
      const result = await RmChangeRequestServices.getRMCRTotalCount(data);
      if (result.status) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
const initialState = {
  RMChangeList: null,
  RMCRCount: null
};
const RMChangeListSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: {
    [getRMChangeList.fulfilled]: (state, action) => {
      state.RMChangeList = action.payload;
    },
    [getRMChangeList.rejected]: (state, action) => {
      state.RMChangeList = null;
    },
    [rmcrCount.fulfilled]: (state, action) => {
      state.RMCRCount = action.payload;
    },
    [rmcrCount.rejected]: (state, action) => {
      state.RMCRCount = null;
    },
  },
});

export default RMChangeListSlice.reducer;
